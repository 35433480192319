import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import { BLOG_CATEGORIES } from '../../consts/blog.consts';
import { RouteFormatter } from '../../routes';
import './style.scss';

const b = bemCn('sniff-blog-top-nav');

const BlogTopNav = () => {
    const [slug, setSlug] = useState('');
    const [isActiveDogNames, setIsActiveDogNames] = useState(false);
    const [isActiveDogRescue, setIsActiveDogRescue] = useState(false);

    useEffect(() => {
        const myUrl = window.location.pathname;
        const splitted = myUrl.includes('/blog/') ? myUrl?.split('/')[2] : myUrl?.split('/')[1] || '';
        setIsActiveDogNames('dog-names' == splitted);
        setIsActiveDogRescue('dog-rescues' == splitted);
        setSlug(splitted);
    }, []);

    return (
        <div className="d-flex justify-content-center">
            <div className={b('top-bar')}>
                {Object.keys(BLOG_CATEGORIES).map((k) => {
                    const v = BLOG_CATEGORIES[k];
                    const isActive = k == slug;
                    return (
                        <a key={k} href={RouteFormatter.blogCategory({ id: v.slug })} className={isActive ? 'cat-active' : ''}>
                            {v.name}
                        </a>
                    );
                })}
                <a href={RouteFormatter.dogNames()} className={isActiveDogNames ? 'cat-active' : ''}>
                    Dog Names
                </a>
                <a href={RouteFormatter.dogRescue()} className={isActiveDogRescue ? 'cat-active' : ''}>
                    Dog Rescues & Shelters
                </a>
            </div>
        </div>
    );
};

export default BlogTopNav;
